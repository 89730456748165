@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }

  h3 {
    @apply text-lg;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-display mb-2 mt-4;
  }

  p {
    @apply py-2;
  }

  body {
    @apply font-body leading-relaxed;
  }

  a {
    @apply underline;
  }

  ul {
    @apply pl-5 mb-4;
  }
}

@layer components {
  .menu-button {
    @apply block w-full h-full py-2 px-4 text-lg font-body font-semibold tracking-widest rounded cursor-pointer no-underline;
  }

  .menu-button:hover {
    @apply bg-gray-300 text-pink-800 ;
  }

  .splitter-container {
    @apply mx-10 mt-12 mb-20 object-contain;
  }

  @media (min-width: 1024px) {
    .splitter-container {
      @apply mx-16 mt-12 mb-20 object-contain;
    }
  }

  .splitter {
    @apply h-20 rendering-crisp;
  }
}

/* * {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */

ul {
  list-style-type: disc;

  ul {
    list-style-type: circle;
  }
}

.bg-magenta-painted {
  background-image: url("../images/2x/left_bg.png");
  background-repeat: repeat-y;
  background-size: 3rem;
  background-position: 0 0;
}

#side-menu-border-right {
  background-image: url("../images/2x/right_bg.png");
  background-repeat: repeat-y;
  background-size: 3.5rem;
  background-position: 0 0;
}

#contact-border {
  background-image: url("../images/2x/contact_bg.png");
  background-repeat: repeat-x;
  background-size: 80rem 100%;
}

.bg-splitter-green {
  background-image: url("../images/2x/splitter.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80% 100%;
}

.h-half {
  height: 50%;
}

.mirror-x {
  transform: scaleX(-1);
}

.rendering-pixelated {
  image-rendering: pixelated;
}

.rendering-crisp {
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.max-h-128 {
  max-height: 32rem;
}
